.board {
    background-color: grey;
    width: 50rem;
    height: 50rem;
    border-collapse: collapse;
}

.row {
    height: 33%;
}

.cell {
    width: 33%;
    border: 1px solid black;
    text-align: center;
}

.cell button {
    width: 100%;
    height: 100%;
    font-size: 10rem;
}
